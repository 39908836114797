<template>
  <header>
    <div class="logo-wrapper">
      <div class="logo">
        <img src="../../assets/header-logo.png" />
      </div>
      <router-link to="/" class="home-link">
        SmartEnglish
        <div class="new-year-icon" v-if="newYearIconShow">
          <img src="../../assets/new-year.png" alt="" srcset="" />
        </div>
      </router-link>
    </div>
    <div class="garland" v-if="newYearIconShow">
      <garland />
      <div class="snowflakes">
        <SnowFlakes />
      </div>
    </div>
    <!-- <vs-breadcrumb>
      <nav v-if="$breadcrumbs.length" aria-label="breadcrumb">
        {{ $breadcrumbs }}
      </nav>
    </vs-breadcrumb> -->
    <Notices />
    <schoolyear-select />
    <GlobalSearch />
  </header>
</template>

<script>
import GlobalSearch from '@/components/common/search'
import SchoolyearSelect from './schoolyearSelect.vue'
import SnowFlakes from './snowflakes.vue'
import garland from './garland.vue'
import Notices from './elements/notices.vue'

export default {
  components: {
    GlobalSearch,
    SchoolyearSelect,
    SnowFlakes,
    Notices,
    garland,
  },
  data() {
    return { newYearIconShow: false }
  },
  created() {},
}
</script>

<style lang="scss">
.content > header {
  width: 100%;
  height: 45px;
  border-bottom: 1px solid #ccc;
  background-color: #fff;
  padding: 5px 25px;
  display: flex;
  flex-flow: row nowrap;
  gap: 1rem;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 401;
  justify-content: space-between;
  .logo-wrapper {
    flex: 1;
    display: flex;
    align-items: center;
    .logo {
      width: 2rem;
      height: 2rem;
      margin-right: 7px;
      // margin-top: -4px;
      img {
        height: 100%;
      }
    }
    a {
      display: block;
    }
    .home-link {
      position: relative;
      .new-year-icon {
        position: absolute;
        right: -2.6rem;
        top: -1.2rem;
        width: 40px;
        height: 40px;
        img {
          width: 100%;
        }
        z-index: -1;
      }
    }
  }
  .schoolyear-select {
    .selected {
      background-color: #eee;
    }
  }
}
.snowflakes {
  position: absolute;
  height: 50px;
  overflow: hidden;
  width: 400px;
  z-index: -1;
  left: -3px;
  top: 0;
}
.garland {
  position: absolute;
  top: 0;
  left: 5px;
  opacity: 0.4;
  z-index: -2;
  width: 100%;
  height: 50px;
}
</style>
